<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <div class="visualizar-execucao-roteiro mt-2">
      <div
        v-for="cliente in clientes"
        :key="cliente.id"
        class="py-4 px-3 visualizar-card"
      >
        <div class="d-flex justify-space-between">
          <div class="d-flex flex-column">
            <span class="primary-grey-500--text">{{
              $t('modulos.roteiro_execucao_envio_material.visualizar.cliente')
            }}</span>
            <span
              class="subtitle-1"
            >{{ cliente.ordem }}/{{ totalClientes }} -
              {{ cliente.nome }}</span>
          </div>
          <div class="d-flex flex-column">
            <span class="primary-grey-500--text">{{
              $t('modulos.roteiro_execucao_envio_material.visualizar.situacao')
            }}</span>
            <template>
              <v-icon
                v-if="cliente.situacao == 'Concluido'"
                color="#12B76A"
              >
                $mdiCheckCircleOutline
              </v-icon>
              <v-icon v-else>
                $clockOutline
              </v-icon>
            </template>
          </div>
        </div>
        <template>
          <botao-padrao
            class="flex-grow-1 flex-md-grow-0 mt-6"
            btn100
            :disabled="cliente.situacao == 'Concluido'"
            @click="checkinCliente(cliente)"
          >
            <v-icon class="mr-2">
              $playCircleOutline
            </v-icon>
            {{
              $t('modulos.roteiro_execucao_envio_material.visualizar.checkIn')
            }}
          </botao-padrao>
        </template>
      </div>
    </div>
    {{ clientesConcluidos }}
    <botao-padrao
      v-if="!clientesConcluidos"
      outlined
      color="secondary"
      class="flex flex-grow-1 mt-4"
      btn100
      @click="receberSemAgendar"
    >
      <v-icon class="mr-2">
        $plus
      </v-icon>
      {{
        $t(
          'modulos.roteiro_execucao_envio_material.botoes.checkOutSemAgendamento'
        )
      }}
    </botao-padrao>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { RoteiroEnvioMaterialService } from '../../../../common/services/cadastros';
import { cloneDeep } from 'lodash';
export default {
  data() {
    return {
      visualizarClientes: [],
    };
  },
  computed: {
    ...mapGetters('Roteiro', ['clientes', 'totalClientes', 'roteiroId', 'ultimoClienteCheckout']),
    tituloFormulario() {
      return this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.roteiro',
        { data: new Date().toLocaleDateString() }
      );
    },
    clientesConcluidos() {
      return this.clientes.every((cliente) => cliente?.situacao === 'Concluido');
    },
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.roteiro_execucao_envio_material.titulos.roteiro', {
        data: null,
      })
    );
  },
  methods: {
    checkinCliente: function (cliente) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroEnvioMaterialService.checkInCliente(this.roteiroId, cliente.id)
        .then(() => {
          const clienteDaVezClonado = cloneDeep(cliente);
          clienteDaVezClonado.situacao = 'EmExecucao';
          clienteDaVezClonado.enviosMateriais =
            clienteDaVezClonado.enviosMateriais.map((envio) => {
              return {
                ...envio,
                situacao: 'EmExecucao',
              };
            });
          this.$store
            .dispatch('Roteiro/atualizarClienteDaVez', clienteDaVezClonado)
            .then(() => {
              this.$router.push({
                name: 'roteiro-executar-envio-material-entrega-recebimento',
              });
            });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    receberSemAgendar: function () {
      this.$router.push({
        name: 'roteiro-executar-envio-material-recebimento-sem-agendamento',
        query:{
          ultimoCheckout: this.ultimoClienteCheckout?.nome
        }
      });
    },
  },
};
</script>
<style lang="scss">
.visualizar-execucao-roteiro {
  width: 100%;
  border: 1px solid #667085;
  border-radius: 4px;
}

.visualizar-card + .visualizar-card {
  border-top: 1px solid #667085;
}
</style>
